var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return undefined
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemEdit: _vm.model,
          loading: _vm.loading,
          find: "",
          showDelete: false,
          "tool-tip-edit": "Ver Extrato",
          "icon-edit": "mdi-card-account-details-outline"
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("emc-table-icon-download-pdf", {
                  attrs: {
                    toolTip: "Extrato Financeiro",
                    type: "payment-export-extract",
                    item: item
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("sys-teacher-financial-extract", {
        attrs: {
          show: _vm.showForm,
          model: _vm.model,
          icon: _vm.$route.meta.icon
        },
        on: {
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }